import React from "react"
import { Link } from "gatsby"

import CookieConsent from "react-cookie-consent"

export default () => (
  <CookieConsent
    location="top"
    buttonText="OK"
    style={{ backgroundColor: "#DE0424" }}
    buttonStyle={{ background: "white", borderRadius: "3px" }}
  >
    Willkommen! Diese Webseite und darin eingebundene externe Dienste verwenden
    Cookies. Weitere Informationen zum Datenschutz, externen Diensten und Ihren
    Rechten finden Sie in unserer{" "}
    <u>
      <Link className="text-reset" to="/impressum#datenschutz">
        Datenschutzerklärung
      </Link>
    </u>
    .
  </CookieConsent>
)
