/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "./layout.css"
import Menu from "./Menu"
import CookieConsent from "./cookie"
import Footer from "./footer"
import WLFrame from "./frame"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            shorttitle
            phone
            phonelink
            fax
            street
            city
            zipcode
          }
        }
      }
    `}
    render={data => (
      <>
        <WLFrame />
        <CookieConsent />
        <div
          className="pr-4"
          style={{
            borderRight: "3px solid #de0424",
            marginRight: "35.5px",
            marginTop: "-37px",
            marginLeft: "20px",
          }}
        >
          <Menu />
          <div>
            <main>{children}</main>
          </div>
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
