import React from "react"
import Logo from "../components/logo"

export default () => (
  <>
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        margin: 0,
        paddingTop: "5px",
        paddingLeft: "5px",
      }}
    >
      <div style={{ flex: "0 0 50px", padding: 0 }}>
        <Logo />
      </div>
      <div
        style={{
          flex: "1",
          height: "75px",
          marginLeft: "-4px",
        }}
      >
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 178.86385 52.942497"
          height="75px"
          width="100%"
        >
          <rect
            x="0"
            y="25.364"
            width="178.86385"
            height="1.8899"
            fill="#de0424"
            strokeWidth=".15649"
          />
        </svg>
      </div>
      <div style={{ flex: "0 0 50px", backgroundColor: "#fff" }}>
        <svg viewBox="0 0 52.942497 52.942497" height="75px">
          <circle
            r="26.47125"
            strokeWidth="2.1"
            fill="none"
            stroke="#DE0424"
            cx="0"
            cy="52.78648"
          />
        </svg>
      </div>
    </div>
  </>
)
