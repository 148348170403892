import React from "react"

import { Link } from "gatsby"
import { Navbar, NavItem, Collapse, NavbarToggler, Nav } from "reactstrap"
import "./Menu.css"

export default class SiteNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <Navbar
        color="transparent"
        light
        expand="lg"
        style={{
          position: "relative",
          top: "-2px",
        }}
      >
        <span className="navbar-brand" />
        <NavbarToggler onClick={this.toggle} />
        <Collapse
          isOpen={this.state.isOpen}
          navbar
          className="justify-content-lg-end justify-content-start"
        >
          <Nav navbar>
            <NavItem>
              <Link className="nav-link" to="/">
                Startseite
              </Link>
            </NavItem>
            <NavItem className="nav-link px-0 d-none d-lg-inline">|</NavItem>
            <NavItem>
              <Link
                to="/ueberuns"
                className="nav-link"
                activeClassName="active"
              >
                Über uns
              </Link>
            </NavItem>
            <NavItem className="nav-link px-0 d-none d-lg-inline">|</NavItem>
            <NavItem>
              <Link
                to="/dienstleistungen"
                className="nav-link"
                activeClassName="active"
              >
                Dienstleistungen
              </Link>
            </NavItem>
            <NavItem className="nav-link px-0 d-none d-lg-inline">|</NavItem>
            <NavItem>
              <Link to="/kontakt" className="nav-link" activeClassName="active">
                Kontakt
              </Link>
            </NavItem>
            <NavItem className="nav-link px-0 d-none d-lg-inline">|</NavItem>
            <NavItem>
              <Link
                to="/impressum"
                className="nav-link"
                activeClassName="active"
              >
                Impressum
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}
/*  <div
  className="d-none d-lg-flex justify-content-end"
  {...props}
  style={{
    position: "relative",
    top: "-30px",
    left: "210px",
    marginRight: "210px",
  }}
>
  <span className="text-center">
    <Link to="/" className="nav-link" activeClassName="active">
      Startseite
    </Link>
  </span>
  <span className="text-center">
    <Link to="/ueberuns" className="nav-link" activeClassName="active">
      Über uns
    </Link>
  </span>
  <span className="text-center">
    <Link
      to="/dienstleistungen"
      className="nav-link"
      activeClassName="active"
    >
      Dienstleistungen
    </Link>
  </span>
  <span className="text-center">
    <Link to="/kontakt" className="nav-link" activeClassName="active">
      Kontakt
    </Link>
  </span>
  <span className="text-center">
    <Link to="/impressum" className="nav-link" activeClassName="active">
      Impressum
    </Link>
  </span>
</div>*/
