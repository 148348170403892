import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Email from "./Email"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            shorttitle
            phone
            phonelink
            fax
            street
            city
            zipcode
          }
        }
      }
    `}
    render={data => (
      <footer className="text-center small py-2">
        © {new Date().getFullYear()} {data.site.siteMetadata.shorttitle}{" "}
        &middot; {data.site.siteMetadata.street} &middot;{" "}
        {data.site.siteMetadata.zipcode} {data.site.siteMetadata.city} &middot;
        Telefon:&nbsp;
        <a
          className="text-dark"
          href={data.site.siteMetadata.phonelink}
          dangerouslySetInnerHTML={{
            __html: data.site.siteMetadata.phone,
          }}
        />{" "}
        &middot; Fax:&nbsp;
        <span
          dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.fax }}
        />{" "}
        &middot; Email:&nbsp;
        <Email />
      </footer>
    )}
  />
)
